import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "listId", "todoId" ]

  static values = {
    baseUrl: String
  }

  connect() {
    if (this._isTurboNativeApp) {
      window.TurboNativeBridge.postMessage("showNativeButton", {
        path: this.baseUrlValue + "/lists/" + this.listIdTarget.innerHTML + "/todos/" + this.todoIdTarget.innerHTML + "/edit",
        imageName: "square.and.pencil"
      })
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
