import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import listPlugin from '@fullcalendar/list';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["calendar", "loading"]

  connect() {
    let _this = this
    let calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      contentHeight: 'auto',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'today',
      },
      events: '/events.json',
      eventDisplay: 'block',
      initialView: 'listWeek',
      editable: true,
      handleWindowResize: true,
      navLinks: false,
      plugins: [listPlugin],
      navLinkDayClick: function(date, jsEvent) {

      },
      eventClick: function (info) {
        info.jsEvent.preventDefault()
        Turbo.visit(info.event.extendedProps.show_url)
      },
      eventDidMount: function(info) {
            // // Change color of dot marker
            // console.log(info.el)
            // var dotEl = info.el.getElementsByClassName('fc-list-event-dot')[0];
            // console.log(dotEl)
            // if (dotEl) {
            //   dotEl.style.backgroundColor = 'red';
            // }
      },
      eventContent: function( info ) {
        return {html: info.event.title};
      },
      loading: function( isLoading, view ) {
        _this.calendarTarget.hidden = isLoading;
        _this.loadingTarget.hidden = !isLoading;
    }
    })
    calendar.render()
  }

  data(info) {
    return {
      "event[start_time]": info.event.start,
      "event[end_time]": info.event.end
    }
  }
}