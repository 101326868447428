import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    const autocomplete = document.getElementById("autocomplete")
    autocomplete.addEventListener('autocomplete.change', (event) => {
      var appliance_brand_id = event.detail.value
      var appliance_generic_type_id = document.querySelector('input[name="home_appliance[appliance_generic_type_id]"]:checked').value
      var new_url = "/appliances/search?appliance_brand_id=" + appliance_brand_id + "&appliance_generic_type_id=" + appliance_generic_type_id
      document.getElementById("model-number").setAttribute("data-autocomplete-url-value", new_url)
    })
  }

}