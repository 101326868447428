import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "id", "name" ]

  static values = {
    url: String,
    id: String,
    baseUrl: String
  }


  connect() {
    if (this._isTurboNativeApp) {
      window.TurboNativeBridge.postMessage("showNativeActionSheet", {
        imageName: "ellipsis",
        title: this.nameTarget.innerHTML,
        actionSheetDataInfo: [
          {
            text: "Add New Task",
            path: this.baseUrlValue + "/lists/" + this.idTarget.innerHTML + "/todos/new",
          },
          {
            text: "Edit List",
            path: this.baseUrlValue + "/lists/" + this.idTarget.innerHTML + "/edit",
          }
        ]
      })
    }
  }

  toggle() {
    Rails.ajax({
      url: this.urlValue,
      type: 'PATCH',
      data: {}
    })
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
