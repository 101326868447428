import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "recurring", "recurringCheckBox", "recurringEndDate", "dueDate" ]

  static values = {
  }

  recurringCheckBoxClicked() {
    if (this.recurringTarget.hidden == true) {
      this.dueDateTarget.innerText = "First Due Date";
    } else {
      this.dueDateTarget.innerText = "Due Date";
    }
    this.recurringTarget.hidden = !this.recurringTarget.hidden;
    this.recurringEndDateTarget.hidden = !this.recurringEndDateTarget.hidden;

  }

  connect() {
    if(this.recurringCheckBoxTarget.checked) {
      this.recurringTarget.hidden = false;
      this.recurringEndDateTarget.hidden = false;
    }
  }
}
