import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "address", "address1", "address2", "city", "state", "zip" ]

  connect() {
    // if (typeof(google) != "undefined") {
    //   this.init()
    // } else {
    //   console.log("Google was undefined");
    // }
    this.init()

  }

  init() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    let address1 = "";
    place.address_components.forEach((address_component) => {
      let types = address_component.types
      types.forEach((type) => {
        if (type == "street_number") {
          address1+= address_component.long_name
        }

        if (type == "route") {
          address1+= " " + address_component.long_name;
        }
        if (address1 += "") {
          this.address1Target.value = address1;
        }

        if (type == "subpremise") {
          this.address2Target.value = address_component.long_name
        }

        if (type == "locality") {
         this.cityTarget.value = address_component.long_name
        }

        if (type == "administrative_area_level_1") {
          this.stateTarget.value = address_component.long_name
         }

         if (type == "postal_code") {
          this.zipTarget.value = address_component.long_name
         }
      });

    });

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

   
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}