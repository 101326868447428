import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    baseUrl: String
  }

  connect() {
    if (this._isTurboNativeApp) {
      window.TurboNativeBridge.postMessage("showNativeButton", {
        path: this.baseUrlValue + "/lists/new",
        imageName: "plus"
      })
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
