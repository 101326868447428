import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    baseUrl: String
  }
  
  connect() {
    if (this._isTurboNativeApp) {
      window.TurboNativeBridge.postMessage("showNativeActionSheet", {
        imageName: "ellipsis",
        title: "New",
        actionSheetDataInfo: [
          {
            text: "New List",
            path: this.baseUrlValue + "/lists/new",
          },
          {
            text: "New Event",
            path: this.baseUrlValue + "/events/new",
          },
          {
            text: "New Contact",
            path: this.baseUrlValue + "/contacts/new",
          }
        ]
      })
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.indexOf("Turbo Native") !== -1
  }
}
